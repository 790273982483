import * as types from './types'
import expenseApi from '../api'

export const actions = {
    async [types.EXPENSE_FETCH]({commit}, data = null) {
        commit(types.EXPENSE_SET_LOADING, true)
        const response = await expenseApi.all(data)
        commit(types.EXPENSE_OBTAIN, response.data.data)
        commit(types.EXPENSE_META, response.data.meta)
        commit(types.EXPENSE_SET_LOADING, false)
    },
}
