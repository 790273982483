/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () =>
    import(/* webpackChunkName: '' */ `./components/${path}`).then(
        m => m.default || m
    );

export const routes = [
    {
        path: "/presupuestos",
        name: "Presupuestos",
        component: page("BudgetList")
    },
    {
        path: "/presupuestos/:id(\\d+)",
        name: "Presupuesto",
        component: page("BudgetView"),
        hidden: true
    },
    {
        path: "/presupuestos/:id(\\d+)/editar",
        name: "Editar Presupuesto",
        component: page("BudgetEdit"),
        hidden: true
    },
    {
        path: "/presupuestos/nuevo",
        name: "Nuevo Presupuesto",
        component: page("BudgetAdd"),
        hidden: true
    }
];
