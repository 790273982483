import esLocale from "element-ui/lib/locale/lang/es";

export const messages = {
    global: {
        ok: "Ok",
        cancel: "Cancelar",
        save: "Guardar",
        add: "Añadir",
        edit: "Editar",
        delete: "Borrar",
        close: "Cerrar",
        view: "Ver",
        new: "Nuevo",
        details: "Detalles",
        search: "Buscar",
        unknown_server_error: "Error del servidor",
        form: {
            rules: {
                required: 'El campo "{fieldName}" es obligatorio',
                email: "Por favor ponga una direcció de mail correcta",
                min:
                    'Field length "{fieldName}" must be more then {attribute} characters',
                max:
                    'Field length "{fieldName}" must be less then {attribute} characters',
                password_repeat: {
                    different: "Las contraseñas son diferentes"
                }
            }
        }
    },
    auth: {
        token_expired_alert_title: "La sesión ha caducado!",
        token_expired_alert_text:
            "Por favor inicíe sesión de nuevo para continuar.",
        verification: {
            resend_link: "Reenviar verificación",
            resend_title: "Reenviar verificación",
            resend_button: "Enviar",
            failed: "El link de verificación es inválido."
        },
        resend_verification_link: "Reenviar verificación de email",
        login: {
            title: "Inicio Sesión",
            submit_button: "Registro",
            email_label: "Email",
            password_label: "Contraseña"
        },
        logout: {
            title: "Salir"
        },
        register: {
            title: "Registro",
            name_label: "Nombre",
            email_label: "Email",
            password_label: "Contraseña",
            password_confirmation_label: "Repita Contraseña",
            submit_button: "Registrarse",
            success: "Gracias por registrarse."
        },
        logout_confirm: {
            title: "Confirmar salir",
            text: "Se cerrará la sesión",
            button_ok: "Ok",
            button_cancel: "Cancelar"
        }
    },
    setting: {
        profile: {
            name: "Nombre",
            email: "Email"
        }
    },
    ...esLocale
};
