import * as types from './types'
import clientApi from '../api'

export const actions = {
    async [types.CLIENT_FETCH]({commit}, data = null) {
        commit(types.CLIENT_SET_LOADING, true)
        const response = await clientApi.all(data)
        commit(types.CLIENT_OBTAIN, response.data.data)
        commit(types.CLIENT_META, response.data.meta)
        commit(types.CLIENT_SET_LOADING, false)
    },
}
