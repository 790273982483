import * as types from "./types";
import employeesApi from "../api";

export const actions = {
    async [types.EMPLOYEES_FETCH]({ commit }, data = null) {
        commit(types.EMPLOYEES_SET_LOADING, true);
        const response = await employeesApi.all(data);
        commit(types.EMPLOYEES_OBTAIN, response.data.data);
        commit(types.EMPLOYEES_META, response.data.meta);
        commit(types.EMPLOYEES_SET_LOADING, false);
    }
};
