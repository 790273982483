import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        anticipos: [],
        anticiposMeta: [],
        anticiposLoading: true,
    },
    getters: {
        anticipos: state => state.anticipos,
        anticiposMeta: state => state.anticiposMeta,
        anticiposLoading: state => state.anticiposLoading,
    },
    mutations: {
        [types.ANTICIPO_OBTAIN](state, anticipos) {
            state.anticipos = anticipos
        },
        [types.ANTICIPO_CLEAR](state) {
            state.anticipos = []
        },
        [types.ANTICIPO_SET_LOADING](state, loading) {
            state.anticiposLoading = loading
        },
        [types.ANTICIPO_META](state, meta) {
            state.anticiposMeta = meta
        },
    },
    actions
}
