import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        materials: [],
        materialsMeta: [],
        materialsLoading: true,
    },
    getters: {
        materials: state => state.materials,
        materialsMeta: state => state.materialsMeta,
        materialsLoading: state => state.materialsLoading,
    },
    mutations: {
        [types.MATERIAL_OBTAIN](state, materials) {
            state.materials = materials
        },
        [types.MATERIAL_CLEAR](state) {
            state.materials = []
        },
        [types.MATERIAL_SET_LOADING](state, loading) {
            state.materialsLoading = loading
        },
        [types.MATERIAL_META](state, meta) {
            state.materialsMeta = meta
        },
    },
    actions
}
