import * as types from './types'
import constructionApi from '../api'

export const actions = {
    async [types.CONSTRUCTION_FETCH]({commit}, data = null) {
        commit(types.CONSTRUCTION_SET_LOADING, true)
        const response = await constructionApi.all(data)
        commit(types.CONSTRUCTION_OBTAIN, response.data.data)
        commit(types.CONSTRUCTION_META, response.data.meta)
        commit(types.CONSTRUCTION_SET_LOADING, false)
    },
}
