/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () =>
    import(/* webpackChunkName: '' */ `./components/${path}`).then(
        m => m.default || m
    );

export const routes = [
    {
        path: "/configuracion/ivas",
        name: "Ivas",
        component: page("IvaList")
    },
    {
        path: "/configuracion/ivas/:id",
        name: "Show Iva",
        component: page("IvaView"),
        hidden: true
    }
];
