import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        expenses: [],
        expensesMeta: [],
        expensesLoading: true,
    },
    getters: {
        expenses: state => state.expenses,
        expensesMeta: state => state.expensesMeta,
        expensesLoading: state => state.expensesLoading,
    },
    mutations: {
        [types.EXPENSE_OBTAIN](state, expenses) {
            state.expenses = expenses
        },
        [types.EXPENSE_CLEAR](state) {
            state.expenses = []
        },
        [types.EXPENSE_SET_LOADING](state, loading) {
            state.expensesLoading = loading
        },
        [types.EXPENSE_META](state, meta) {
            state.expensesMeta = meta
        },
    },
    actions
}
