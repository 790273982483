<template>
    <div id="not-found" class="d-flex flex-column justify-content-center">
        <div class="logo">
            <img
                src="/images/innovacionsUrbanesLogo.png"
                alt="Innovacions Urbanes Logo"
            />
        </div>
        <h1 class="text-center mb-4">Página no disponible</h1>
        <el-button @click="goHome"> Volver al Inicio</el-button>
    </div>
</template>

<script>
export default {
    name: "NotFound",
    methods: {
        goHome() {
            if (this.$auth.check()) {
                this.$router.push({ name: "Inicio" });
            } else {
                this.$router.push({ name: "Login" });
            }
        }
    }
};
</script>

<style lang="scss">
#not-found {
    h1 {
        font-size: 30px;
    }
    .logo {
        margin-bottom: 4rem;

        img {
            width: 18rem;
        }
    }
}
</style>
