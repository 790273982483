import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        articles: [],
        articlesMeta: [],
        articlesLoading: true,
    },
    getters: {
        articles: state => state.articles,
        articlesMeta: state => state.articlesMeta,
        articlesLoading: state => state.articlesLoading,
    },
    mutations: {
        [types.ARTICLE_OBTAIN](state, articles) {
            state.articles = articles
        },
        [types.ARTICLE_CLEAR](state) {
            state.articles = []
        },
        [types.ARTICLE_SET_LOADING](state, loading) {
            state.articlesLoading = loading
        },
        [types.ARTICLE_META](state, meta) {
            state.articlesMeta = meta
        },
    },
    actions
}
