import Vue from "vue";
import App from "./core/App";
import ElementUI from "element-ui";
import i18n from "./bootstrap/i18n";
import router from "./bootstrap/router";
import store from "./core/store";
import globalMixin from "./includes/mixins/globalMixin";
import Print from "vue-print-nb";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";

import "./bootstrap/auth";
import "./bootstrap/moment";

try {
    window.Popper = require("popper.js").default;
    window.$ = window.jQuery = require("jquery");
    require("bootstrap");
    window._ = require("lodash");
    window.moment = require("moment");
} catch (e) {
    console.log("Error load main libraries");
}

Vue.filter("formatData", function(date) {
    return moment(date).format("DD-MM-YYYY");
});

Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value) });

Vue.prototype.config = window.config;

Vue.mixin(globalMixin);

// Docs: https://github.com/Power-kxLee/vue-print-nb
Vue.use(Print);

// Docs: https://sentry.io
Sentry.init({
    dsn:
        "https://f47c9e18f6be49dab19fee285ddee6d2@o485143.ingest.sentry.io/5539854",
    integrations: [
        new VueIntegration({
            Vue,
            tracing: true,
            logErrors: true
        }),
        new Integrations.BrowserTracing()
    ],
    environment: process.env.NODE_ENV,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
});

window.Vue = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount("#app");
