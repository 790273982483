/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () =>
    import(/* webpackChunkName: '' */ `./components/${path}`).then(
        m => m.default || m
    );

export const routes = [
    {
        path: "/configuracion/categorias",
        name: "Categorías",
        component: page("CategoryList")
    },
    {
        path: "/configuracion/categorias/:id",
        name: "Categoria",
        component: page("CategoryView")
    }
];
