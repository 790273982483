import * as types from './types'
import budgetLineApi from '../api'

export const actions = {
    async [types.BUDGET_LINE_FETCH]({commit}, data = null) {
        commit(types.BUDGET_LINE_SET_LOADING, true)
        const response = await budgetLineApi.all(data)
        commit(types.BUDGET_LINE_OBTAIN, response.data.data)
        commit(types.BUDGET_LINE_META, response.data.meta)
        commit(types.BUDGET_LINE_SET_LOADING, false)
    },
}
