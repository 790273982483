import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        ivas: [],
        ivasMeta: [],
        ivasLoading: true,
    },
    getters: {
        ivas: state => state.ivas,
        ivasMeta: state => state.ivasMeta,
        ivasLoading: state => state.ivasLoading,
    },
    mutations: {
        [types.IVA_OBTAIN](state, ivas) {
            state.ivas = ivas
        },
        [types.IVA_CLEAR](state) {
            state.ivas = []
        },
        [types.IVA_SET_LOADING](state, loading) {
            state.ivasLoading = loading
        },
        [types.IVA_META](state, meta) {
            state.ivasMeta = meta
        },
    },
    actions
}
