import * as types from "./types";
import { actions } from "./actions";

export const store = {
    state: {
        employees: [],
        employeesMeta: [],
        employeesLoading: true
    },
    getters: {
        employees: state => state.employees,
        employeesMeta: state => state.employeesMeta,
        employeesLoading: state => state.employeesLoading
    },
    mutations: {
        [types.EMPLOYEES_OBTAIN](state, employees) {
            state.employees = employees;
        },
        [types.EMPLOYEES_CLEAR](state) {
            state.employees = [];
        },
        [types.EMPLOYEES_SET_LOADING](state, loading) {
            state.employeesLoading = loading;
        },
        [types.EMPLOYEES_META](state, meta) {
            state.employeesMeta = meta;
        }
    },
    actions
};
