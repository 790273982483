import {
    messages as es
} from './es'
import {
    messages as ca
} from './ca'
import {
    messages as en
} from './en'


export default {
    es,
    ca,
    en
}
