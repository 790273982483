import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        budgets: [],
        budgetsMeta: [],
        budgetsLoading: true,
    },
    getters: {
        budgets: state => state.budgets,
        budgetsMeta: state => state.budgetsMeta,
        budgetsLoading: state => state.budgetsLoading,
    },
    mutations: {
        [types.BUDGET_OBTAIN](state, budgets) {
            state.budgets = budgets
        },
        [types.BUDGET_CLEAR](state) {
            state.budgets = []
        },
        [types.BUDGET_SET_LOADING](state, loading) {
            state.budgetsLoading = loading
        },
        [types.BUDGET_META](state, meta) {
            state.budgetsMeta = meta
        },
    },
    actions
}
