import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        expenseTypes: [],
        expenseTypesMeta: [],
        expenseTypesLoading: true,
    },
    getters: {
        expenseTypes: state => state.expenseTypes,
        expenseTypesMeta: state => state.expenseTypesMeta,
        expenseTypesLoading: state => state.expenseTypesLoading,
    },
    mutations: {
        [types.EXPENSE_TYPE_OBTAIN](state, expenseTypes) {
            state.expenseTypes = expenseTypes
        },
        [types.EXPENSE_TYPE_CLEAR](state) {
            state.expenseTypes = []
        },
        [types.EXPENSE_TYPE_SET_LOADING](state, loading) {
            state.expenseTypesLoading = loading
        },
        [types.EXPENSE_TYPE_META](state, meta) {
            state.expenseTypesMeta = meta
        },
    },
    actions
}
