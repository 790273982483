<template>
  <section id="dashboard">
    <!-- ACTIVE CONSTRUCTIONS -->
    <h1 class="mb-4 text-center text-md-left font-weight-bold">Obras activas</h1>
    <!-- ACTIVE CONSTRUCTIONS DESKTOP -->
    <el-row class="hidden-xs-only mb-4" v-if="activeConstructions.length > 0">
      <el-col :span="24">
        <el-carousel
          type="card"
          :autoplay="false"
          :arrow="'always'"
          :trigger="'click'"
          :indicator-position="'none'"
          @change="onCurrentConstructionChanged"
        >
          <el-carousel-item
            v-for="construction in activeConstructions"
            :key="`construction-${construction.id}`"
          >
            <el-card class="box-card text-center">
              <div slot="header" class="clearfix">
                <b>{{ construction.name }}</b>
              </div>
              <div>
                <p>
                  <b>Presupuesto:</b>
                  {{ constructionBudget(construction) }}
                </p>
                <p>
                  <b>Gastos:</b>
                  <br/>
                  PVP: {{ expensesTotalPVP(construction) }} | Coste: {{ expensesTotalCoste(construction) }}
                </p>
                <p>
                  <b>Beneficios:</b>
                  <br/>
                  PVP: {{ profitPVP(construction) }} | Coste: {{ profitCoste(construction) }}
                </p>
              </div>
              <div class="d-flex justify-content-center">
                <el-button
                  type="primary"
                  size="mini"
                  @click="viewConstruction(construction)"
                >
                  Ver detalles
                </el-button>
              </div>
            </el-card>
          </el-carousel-item>
        </el-carousel>
      </el-col>
    </el-row>
    <!-- ACTIVE CONSTRUCTIONS SMARTPHONE -->
    <el-row class="hidden-sm-and-up mb-4" v-if="activeConstructions.length > 0">
      <el-col :span="24">
        <el-carousel
          :autoplay="false"
          :arrow="'always'"
          :trigger="'click'"
          :indicator-position="'none'"
          @change="onCurrentConstructionChanged"
        >
          <el-carousel-item
            v-for="construction in activeConstructions"
            :key="`construction-${construction.id}`"
            class="px-2"
          >
            <el-card class="box-card my-4 w-100 text-center">
              <div slot="header" class="clearfix">
                <b>{{ construction.name }}</b>
              </div>
              <div>
                <p>
                  <span class="font-weight-bold underlined">Presupuesto:</span>
                  {{ constructionBudget(construction) }}
                </p>
                <p>
                  <span class="font-weight-bold underlined">Gastos:</span>
                  <br/>
                  PVP: {{ expensesTotalPVP(construction) }} | Coste: {{ expensesTotalCoste(construction) }}
                </p>
                <p>
                  <span class="font-weight-bold underlined">Beneficios:</span>
                  <br/>
                  PVP: {{ profitPVP(construction) }} | Coste: {{ profitCoste(construction) }}
                </p>
              </div>
              <div class="d-flex justify-content-center">
                <el-button
                  type="primary"
                  size="mini"
                  @click="viewConstruction(construction)"
                >
                  Ver detalles
                </el-button>
              </div>
            </el-card>
          </el-carousel-item>
        </el-carousel>
      </el-col>
    </el-row>
    <!-- NO ACTIVE CONSTRUCTIONS -->
    <el-row class="mb-4" v-if="activeConstructions.length === 0">
      <el-col :span="24">
        <el-alert
          title="No hay ninguna obra activa"
          type="info"
          :closable="false"
        />
      </el-col>
    </el-row>
    <!-- LAST EXPENSES -->
    <h1
      v-if="activeConstructions.length > 0"
      class="mb-4 text-center text-md-left font-weight-bold"
    >
      Últimos gastos
    </h1>
    <!-- LAST EXPENSES DESKTOP -->
    <el-row v-if="activeConstructions.length > 0" class="hidden-xs-only">
      <el-col :span="24">
        <el-table
          :data="selectedConstructionExpenses"
          class="w-100"
          empty-text="Todavía no se ha añadido ningún gasto para esta obra"
        >
          <el-table-column
            label="Fecha"
            prop="expense_date"
            sortable
            width="130"
          >
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">
                {{ GlobalFormatDate(scope.row.expense_date) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Importe PVP" prop="total_price" sortable>
            <template slot-scope="scope">
              {{ GlobalFormatCurrency(scope.row.total_price / 100) }}
            </template>
          </el-table-column>
          <el-table-column label="Importe Coste" prop="total_costPrice" sortable>
            <template slot-scope="scope">
              {{ GlobalFormatCurrency(scope.row.total_costPrice / 100) }}
            </template>
          </el-table-column>
          <el-table-column label="Gasto" prop="name">
            <template slot-scope="scope">
              <div>
                {{ scope.row.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Tipo" prop="expense_type_id" sortable>
            <template slot-scope="scope">
              <div>
                {{ scope.row.type ? scope.row.type.name : "No disponible" }}
              </div>

              <div v-if="scope.row.type && scope.row.type.id === 1">
                <small>
                  {{ scope.row.employee ? scope.row.employee.name : null }}
                </small>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- LAST EXPENSES SMARTPHONE -->
    <el-row v-if="activeConstructions.length > 0" class="hidden-sm-and-up">
      <el-col :span="24">
        <el-table
          :data="selectedConstructionExpenses"
          class="w-100"
          empty-text="Todavía no se ha añadido ningún gasto para esta obra"
        >
          <!-- EXPAND -->
          <el-table-column type="expand">
            <template slot-scope="scope">
              <p>
                Tipo:
                {{ scope.row.type ? scope.row.type.name : "No disponible" }}
              </p>
              <p v-if="scope.row.type && scope.row.type.id === 1">
                Trabajador:
                {{
                  scope.row.employee ? scope.row.employee.name : "No disponible"
                }}
              </p>
            </template>
          </el-table-column>

          <!-- NAME -->
          <el-table-column label="Gasto" prop="name">
            <template slot-scope="scope">
              <div>
                {{ scope.row.name }}
              </div>
              <small>
                {{ GlobalFormatDate(scope.row.expense_date) }} -
                {{ GlobalFormatCurrency(scope.row.total_price / 100) }}
              </small>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import constructionApi from "../../construction/api";

export default {
  name: "Dashboard",
  data: () => ({
    activeConstructions: [],
    selectedConstruction: null,
  }),
  computed: {
    selectedConstructionExpenses() {
      if (this.activeConstructions.length === 0) return [];
      return this.selectedConstruction.expenses
        ? this.selectedConstruction.expenses
            .sort(function (a, b) {
              return (
                // Sort order: b - a = desc, a - b = asc
                new Date(b.expense_date) - new Date(a.expense_date)
              );
            })
            .slice(0, 5)
        : [];
    },
  },
  methods: {
    fetchAtiveConstructions() {
      const loading = this.$loading();

      let params = {
        sortBy: this.sortBy,
        pageSize: 10000,
        status: "ACTIVE",
      };
      return new Promise((resolve, reject) => {
        constructionApi
          .all(params)
          .then((response) => resolve(response.data.data))
          .catch(() => resolve([]))
          .finally(() => loading.close());
      });
    },
    constructionBudget(construction) {
      if (!construction.budget) return "No disponible";
      return this.GlobalFormatCurrency(construction.budget / 100);
    },
    expensesSumPVP(construction) {
      return construction.expenses.reduce(
        (total, expense) => (total += expense.total_price),
        0
      );
    },
    expensesSumCoste(construction) {
      return construction.expenses.reduce(
        (total, expense) => (total += expense.total_costPrice),
        0
      );
    },
    expensesTotalPVP(construction) {
      if (construction.expenses.length === 0)
        return this.GlobalFormatCurrency(0);
      return this.GlobalFormatCurrency(this.expensesSumPVP(construction) / 100);
    },
    expensesTotalCoste(construction) {
      if (construction.expenses.length === 0)
        return this.GlobalFormatCurrency(0);
      return this.GlobalFormatCurrency(this.expensesSumCoste(construction) / 100);
    },
    profitPVP(construction) {
      if (construction.expenses.length === 0)
        return this.constructionBudget(construction);
      return this.GlobalFormatCurrency(
        (construction.budget - this.expensesSumPVP(construction)) / 100
      );
    },
    profitCoste(construction) {
      if (construction.expenses.length === 0)
        return this.constructionBudget(construction);
      return this.GlobalFormatCurrency(
        (construction.budget - this.expensesSumCoste(construction)) / 100
      );
    },
    viewConstruction(construction) {
      if (!construction) return;
      this.$router.push({
        name: "Obra",
        params: { id: construction.id },
      });
    },
    onCurrentConstructionChanged(index) {
      this.selectedConstruction = this.activeConstructions[index];
    },
  },
  async created() {
    this.activeConstructions = await this.fetchAtiveConstructions();
    this.selectedConstruction = this.activeConstructions[0];
  },
};
</script>

<style lang="scss">
#dashboard {
  h1 {
    font-size: 22px;
  }
  .box-card {
    width: 70%;
    margin: 0 auto;
  }
  .underlined {
    text-decoration: underline;
  }
}
</style>
