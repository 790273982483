import * as types from './types'
import materialApi from '../api'

export const actions = {
    async [types.MATERIAL_FETCH]({commit}, data = null) {
        commit(types.MATERIAL_SET_LOADING, true)
        const response = await materialApi.all(data)
        commit(types.MATERIAL_OBTAIN, response.data.data)
        commit(types.MATERIAL_META, response.data.meta)
        commit(types.MATERIAL_SET_LOADING, false)
    },
}
