import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        constructions: [],
        constructionsMeta: [],
        constructionsLoading: true,
    },
    getters: {
        constructions: state => state.constructions,
        constructionsMeta: state => state.constructionsMeta,
        constructionsLoading: state => state.constructionsLoading,
    },
    mutations: {
        [types.CONSTRUCTION_OBTAIN](state, constructions) {
            state.constructions = constructions
        },
        [types.CONSTRUCTION_CLEAR](state) {
            state.constructions = []
        },
        [types.CONSTRUCTION_SET_LOADING](state, loading) {
            state.constructionsLoading = loading
        },
        [types.CONSTRUCTION_META](state, meta) {
            state.constructionsMeta = meta
        },
    },
    actions
}
