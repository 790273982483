import * as types from './types'
import anticipoApi from '../api'

export const actions = {
    async [types.ANTICIPO_FETCH]({commit}, data = null) {
        commit(types.ANTICIPO_SET_LOADING, true)
        const response = await anticipoApi.all(data)
        commit(types.ANTICIPO_OBTAIN, response.data.data)
        commit(types.ANTICIPO_META, response.data.meta)
        commit(types.ANTICIPO_SET_LOADING, false)
    },
}
