<template>
  <div class="app">
    <transition name="fade" mode="out-in">
      <router-view :key="$route.fullPath"></router-view>
    </transition>
  </div>
</template>

<script>
import notificationMixin from "../modules/notification/components/notificationMixin";
export default {
  name: "App",
  mixins: [notificationMixin],
};
</script>

<style lang="scss">
// Main styles
.el-message-box {
  width: auto !important;
}
.el-table__expanded-cell[class*="cell"] {
  padding: 20px 20px;
}
.home-container .main aside {
  overflow: auto;
}
.el-form-item__label {
  font-weight: bold;
}
.el-input {
  input {
    &::-moz-placeholder {
      color: #000;
    }

    &:-ms-input-placeholder {
      /** notice that ie has only a single colon) */
      color: #000;
    }

    &::-webkit-input-placeholder {
      color: #000;
    }

    &::placeholder {
      color: #000;
    }
  }
}
.el-radio-button__inner {
  color: #000;
}
.el-card__header {
  font-weight: bold;
  text-decoration: underline;
}
.el-card__body {
  font-weight: bold;
}
.el-table__header {
  .cell {
    font-weight: bold;
    color: #000 !important;
  }
}
.el-table__row {
  .cell {
    color: #000 !important;
    font-weight: bold;
  }
}
.el-button {
  font-weight: bold;
}
.el-button--default {
  color: #000;
}
.el-dialog__header {
  font-weight: bold;
}
.el-dialog__body {
    color: #000;
}
.el-menu-item, .el-submenu__title {
  color:#000;
  font-weight: bold;
}
</style>
