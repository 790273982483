/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () => import( /* webpackChunkName: '' */ `./components/${path}`).then(m => m.default || m)

export const routes = [{
        path: '/configuracion/tipos-de-gasto',
        name: 'Tipos de gasto',
        component: page('ExpenseTypeList'),
    },
    {
        path: '/configuracion/tipos-de-gasto/:id',
        name: 'Tipo de gasto',
        component: page('ExpenseTypeView'),
        hidden: true
    }
]
