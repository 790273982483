const globalDateTimeDBFormat = "YYYY-MM-DD HH:mm:ss";
const globalDateFormat = "DD/MM/YYYY";
const globalDateTimeFormat = "DD/MM/YYYY HH:mm";
export default {
    data: () => ({
        globalDateFormat: globalDateFormat,
        globalDateTimeFormat: globalDateTimeFormat,
        globalDateTimeDBFormat: globalDateTimeDBFormat,
        globalPageSize: 10
    }),
    filters: {
        capitalize: function(value) {
            if (!value) return "";
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
    },
    methods: {
        /**
         * @return {string}
         */
        GlobalFormatDate(datetime) {
            return moment(datetime, globalDateTimeDBFormat).format(
                globalDateFormat
            );
        },
        /**
         * @return {string}
         */
        GlobalFormatDatabaseDate(datetime) {
            return moment(new Date(datetime), globalDateFormat).format(
                globalDateTimeDBFormat
            );
        },
        /**
         * @return {string}
         */
        GlobalFormatTime(datetime) {
            return moment(datetime, globalDateTimeDBFormat).format(
                globalDateTimeFormat
            );
        },
        /**
         * @return {string}
         */
        GlobalFormatCurrency(number) {
            return new Intl.NumberFormat("es-ES", {
                style: "currency",
                currency: "EUR"
            }).format(number);
        },
        /**
         * @return {string}
         */
        GlobalFormatRatePrice(number) {
            return `${new Intl.NumberFormat("es-ES", {
                style: "currency",
                currency: "EUR"
            }).format(number)}/hora`;
        },
        /**
         * @return {string}
         */
        GlobalPositionName(value) {
            if (!value) return;
            value = value.toString().toUpperCase();
            switch (value) {
                case "OFICIAL1":
                    return "Oficial 1ª";
                case "OFICIAL2":
                    return "Oficial 2ª";
                case "PEON":
                    return "Peón";
                case "ENCARGADO":
                    return "Encargado";
            }
        },
        /**
         * @return {string}
         */
        GlobalStatusBudgetName(value) {
            if (!value) return;
            value = value.toString().toUpperCase();
            switch (value) {
                case "DRAFT":
                    return "Borrador";
                case "SENT":
                    return "Enviado";
                case "ACCEPTED":
                    return "Aceptado";
                case "DECLINED":
                    return "Descartado";
            }
        },
        /**
         * @return {string}
         */
        GlobalRandomString(length) {
            let result = "";
            let chars =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            let charsLength = chars.length;
            for (let i = 0; i < length; i++) {
                result += chars.charAt(Math.floor(Math.random() * charsLength));
            }
            return result;
        }
    }
};
