/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () => import( /* webpackChunkName: '' */ `./components/${path}`).then(m => m.default || m)

export const routes = [{
    path: '/configuracion/perfil',
    name: 'Perfil',
    component: page('Profile'),
}, ];
