import * as types from './types'
import articleApi from '../api'

export const actions = {
    async [types.ARTICLE_FETCH]({commit}, data = null) {
        commit(types.ARTICLE_SET_LOADING, true)
        const response = await articleApi.all(data)
        commit(types.ARTICLE_OBTAIN, response.data.data)
        commit(types.ARTICLE_META, response.data.meta)
        commit(types.ARTICLE_SET_LOADING, false)
    },
}
