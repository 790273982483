import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        clients: [],
        clientsMeta: [],
        clientsLoading: true,
    },
    getters: {
        clients: state => state.clients,
        clientsMeta: state => state.clientsMeta,
        clientsLoading: state => state.clientsLoading,
    },
    mutations: {
        [types.CLIENT_OBTAIN](state, clients) {
            state.clients = clients
        },
        [types.CLIENT_CLEAR](state) {
            state.clients = []
        },
        [types.CLIENT_SET_LOADING](state, loading) {
            state.clientsLoading = loading
        },
        [types.CLIENT_META](state, meta) {
            state.clientsMeta = meta
        },
    },
    actions
}
