import * as types from './types'
import budgetApi from '../api'

export const actions = {
    async [types.BUDGET_FETCH]({commit}, data = null) {
        commit(types.BUDGET_SET_LOADING, true)
        const response = await budgetApi.all(data)
        commit(types.BUDGET_OBTAIN, response.data.data)
        commit(types.BUDGET_META, response.data.meta)
        commit(types.BUDGET_SET_LOADING, false)
    },
}
