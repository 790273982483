/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () =>
    import(/* webpackChunkName: '' */ `./components/${path}`).then(
        m => m.default || m
    );

export const routes = [
    {
        path: "/clientes",
        name: "Clientes",
        component: page("ClientList")
    },
    {
        path: "/clientes/todos",
        name: "Todos los clientes",
        component: page("ClientListAll")
    },
    {
        path: "/clientes/:id",
        name: "client-view",
        component: page("ClientView")
    }
];
