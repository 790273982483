/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () =>
    import(/* webpackChunkName: '' */ `./components/${path}`).then(
        m => m.default || m
    );

export const routes = [
    {
        path: "/budgetLines",
        name: "BudgetLines",
        component: page("BudgetLineList")
    },
    {
        path: "/budgetLines/:id",
        name: "Show BudgetLine",
        component: page("BudgetLineView")
    }
];
