import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        budgetLines: [],
        budgetLinesMeta: [],
        budgetLinesLoading: true,
    },
    getters: {
        budgetLines: state => state.budgetLines,
        budgetLinesMeta: state => state.budgetLinesMeta,
        budgetLinesLoading: state => state.budgetLinesLoading,
    },
    mutations: {
        [types.BUDGET_LINE_OBTAIN](state, budgetLines) {
            state.budgetLines = budgetLines
        },
        [types.BUDGET_LINE_CLEAR](state) {
            state.budgetLines = []
        },
        [types.BUDGET_LINE_SET_LOADING](state, loading) {
            state.budgetLinesLoading = loading
        },
        [types.BUDGET_LINE_META](state, meta) {
            state.budgetLinesMeta = meta
        },
    },
    actions
}
