import * as types from './types'
import expenseTypeApi from '../api'

export const actions = {
    async [types.EXPENSE_TYPE_FETCH]({
        commit
    }, data = null) {
        commit(types.EXPENSE_TYPE_SET_LOADING, true)
        const response = await expenseTypeApi.all(data)
        commit(types.EXPENSE_TYPE_OBTAIN, response.data.data)
        commit(types.EXPENSE_TYPE_META, response.data.meta)
        commit(types.EXPENSE_TYPE_SET_LOADING, false)
    },
}
