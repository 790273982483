/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () => import( /* webpackChunkName: '' */ `./components/${path}`).then(m => m.default || m)

export const routes = [{
        path: '/obras',
        name: 'Obras',
        component: page('ConstructionList'),
    },
    {
        path: '/obras/:id',
        name: 'Obra',
        component: page('ConstructionView'),
        hidden: true
    }
]
