/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () => import(/* webpackChunkName: '' */ `./components/${path}`).then(m => m.default || m)

export const routes = [
    {
        path: '/expenses',
        name: 'Gastos',
        component: page('ExpenseList'),
        hidden: true
    },
    {
        path: '/expenses/:id',
        name: 'Gasto',
        component: page('ExpenseView'),
        hidden: true
    }
]
