import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        units: [],
        unitsMeta: [],
        unitsLoading: true,
    },
    getters: {
        units: state => state.units,
        unitsMeta: state => state.unitsMeta,
        unitsLoading: state => state.unitsLoading,
    },
    mutations: {
        [types.UNIT_OBTAIN](state, units) {
            state.units = units
        },
        [types.UNIT_CLEAR](state) {
            state.units = []
        },
        [types.UNIT_SET_LOADING](state, loading) {
            state.unitsLoading = loading
        },
        [types.UNIT_META](state, meta) {
            state.unitsMeta = meta
        },
    },
    actions
}
