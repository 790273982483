import * as types from './types'
import unitApi from '../api'

export const actions = {
    async [types.UNIT_FETCH]({commit}, data = null) {
        commit(types.UNIT_SET_LOADING, true)
        const response = await unitApi.all(data)
        commit(types.UNIT_OBTAIN, response.data.data)
        commit(types.UNIT_META, response.data.meta)
        commit(types.UNIT_SET_LOADING, false)
    },
}
