<template>
    <aside>
        <el-menu
            :default-active="$route.path"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
            @select="handleSelect"
            router
            :collapse="coreIsCollapsed"
        >
            <el-menu-item :route="{ name: 'Inicio' }" index="/">
                <i :class="'el-icon-house'"></i>
                <span slot="title">Inicio</span>
            </el-menu-item>
            <el-menu-item :route="{ name: 'Obras' }" index="/obras">
                <i :class="'el-icon-school'"></i>
                <span slot="title">Obras</span>
            </el-menu-item>
            <el-submenu index="3">
                <template slot="title">
                    <i :class="'el-icon-folder-opened'"></i>
                    <span slot="title">Presupuestos</span>
                </template>
                <el-menu-item
                    :route="{ name: 'Presupuestos' }"
                    index="/presupuestos"
                >
                    <i :class="'el-icon-document'"></i>
                    <span slot="title">Listado Presupuestos</span>
                </el-menu-item>
                <el-menu-item
                    :route="{ name: 'Nuevo Presupuesto' }"
                    index="/presupuestos/nuevo"
                >
                    <i :class="'el-icon-plus'"></i>
                    <span slot="title">Nuevo Presupuesto</span>
                </el-menu-item>
            </el-submenu>
            <el-submenu index="4">
                <template slot="title">
                    <i :class="'el-icon-brush'"></i>
                    <span slot="title">Trabajadores</span>
                </template>
                <el-menu-item
                    :route="{ name: 'Trabajadores' }"
                    index="/trabajadores"
                >
                    <i :class="'el-icon-document'"></i>
                    <span slot="title">Listado Trabajadores</span>
                </el-menu-item>
                <el-menu-item :route="{ name: 'Anticipos' }" index="/anticipos">
                    <i :class="'el-icon-money'"></i>
                    <span slot="title">Anticipos</span>
                </el-menu-item>
            </el-submenu>
            <el-menu-item :route="{ name: 'Clientes' }" index="/clientes">
                <i :class="'el-icon-user'"></i>
                <span slot="title">Clientes</span>
            </el-menu-item>
            <el-submenu index="6">
                <template slot="title">
                    <i :class="'el-icon-setting'"></i>
                    <span slot="title">Configuración</span>
                </template>
                <el-menu-item
                    :route="{ name: 'Artículos' }"
                    index="/configuracion/articulos"
                >
                    <i :class="'el-icon-files'"></i>
                    <span slot="title">Articulos</span>
                </el-menu-item>
                <el-menu-item
                    :route="{ name: 'Materiales' }"
                    index="/configuracion/materiales"
                >
                    <i :class="'el-icon-box'"></i>
                    <span slot="title">Materiales</span>
                </el-menu-item>
                <el-menu-item
                    :route="{ name: 'Categorías' }"
                    index="/configuracion/categorias"
                >
                    <i :class="'el-icon-price-tag'"></i>
                    <span slot="title">Categorias</span>
                </el-menu-item>
                <el-menu-item
                    :route="{ name: 'Ivas' }"
                    index="/configuracion/ivas"
                >
                    <i :class="'el-icon-coordinate'"></i>
                    <span slot="title">IVAs</span>
                </el-menu-item>

                <el-menu-item
                    :route="{ name: 'Tipos de gasto' }"
                    index="/configuracion/tipos-de-gasto"
                >
                    <i :class="'el-icon-price-tag'"></i>
                    <span slot="title">Tipos de gastos</span>
                </el-menu-item>
                <el-menu-item
                    :route="{ name: 'Unidades' }"
                    index="/configuracion/unidades"
                >
                    <i :class="'el-icon-attract'"></i>
                    <span slot="title">Unidades</span>
                </el-menu-item>
                <!-- <el-menu-item
                    :route="{ name: 'Register' }"
                    index="/configuracion/unidades"
                >
                    <i :class="'el-icon-circle-plus-outline'"></i>
                    <span slot="title">Registrar usuario</span>
                </el-menu-item> -->
            </el-submenu>
            <!-- <el-submenu index="5">
                <template slot="title">
                    <i :class="'el-icon-setting'"></i>
                    <span slot="title">Configuración</span>
                </template>
                <el-menu-item
                    :route="{ name: 'Perfil' }"
                    index="/configuracion/perfil"
                >
                    <i :class="'el-icon-user'"></i>
                    <span slot="title">Perfil</span>
                </el-menu-item>
            </el-submenu> -->
            <el-menu-item @click="logout">
                <i :class="'el-icon-turn-off'"></i>
                <span>{{ $t("auth.logout.title") }}</span>
            </el-menu-item>
        </el-menu>
    </aside>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "Sidebar",
    components: {},
    data: () => ({}),
    methods: {
        menuItemIsAvailable(item) {
            let allowed = !item.meta.hidden;
            if (allowed && item.meta && item.meta.auth) {
                allowed = this.$auth.check(item.meta.auth);
            }
            return allowed;
        },
        handleOpen() {
            //console.log('handleOpen');
        },
        handleClose() {
            //console.log('handleClose');
        },
        handleSelect: function(a, b) {},
        logout: function() {
            this.$confirm(
                this.$t("auth.logout_confirm.text"),
                this.$t("auth.logout_confirm.title"),
                {
                    confirmButtonText: this.$t("auth.logout_confirm.button_ok"),
                    cancelButtonText: this.$t(
                        "auth.logout_confirm.button_cancel"
                    )
                }
            ).then(() => {
                this.$auth.logout();
            });
        }
    },
    computed: {
        ...mapGetters(["coreIsCollapsed"]),
        admin_routes() {
            debugger;
            return this.$router.options.routes[0].children;
        }
    }
};
</script>

<style lang="scss" scoped></style>
